//**************************************************************
// GSAP ANIMATION
//**************************************************************
// COMMON ANIMATION
// ==================================================
// FADE IN UP ------------------------------//
var target = gsap.utils.toArray(".fadeInUp");
gsap.utils.toArray(".fadeInUp").forEach((target) => {
  gsap.timeline({
    scrollTrigger: {
      trigger: target,
      start: "top 80%",
      end: "bottom 80%",
      toggleActions: "play none none none"
    }
  })
    .from(target, {
      y: 60, 
      autoAlpha: 0, 
      scale: 0.9, 
      stagger: 0.5,
      filter: "blur(10px)",
    })
    .to(target, {
      y: 0, 
      autoAlpha: 1, 
      scale: 1, 
      ease: "expo.inOut", 
      duration: 1,
      filter: "blur(0px)",
    })
});

// FADE IN UP（タイトルテンプレート） ------------------------------//
var target = gsap.utils.toArray(".title-template");
gsap.utils.toArray(".title-template").forEach((target) => {
  gsap.timeline({
    scrollTrigger: {
      trigger: target,
      start: "top 80%",
      end: "bottom 80%",
      toggleActions: "play none none none"
    }
  })
    .from(target, {
      y: 60, autoAlpha: 0, scale: 0.95, stagger: 0.5,
    })
    .to(target, {
      y: 0, autoAlpha: 1, scale: 1, ease: "expo.inOut", duration: 1,
    })
});


// BLUR アニメーション（ぼかし） ------------------------------//
var target = gsap.utils.toArray(".blur");
gsap.utils.toArray(".blur").forEach((target) => {
  gsap.timeline({
    scrollTrigger: {
      trigger: target,
      start: "top 80%",
      end: "bottom 80%",
      toggleActions: "play none none none"
    }
  })
    .from(target, {
      filter: "blur(10px)",
    })
    .to(target, {
      filter: "blur(0px)", ease: "expo.inOut", duration: 1,
    })
});


// ONLINE SHOP BTN ------------------------------//
gsap.to(".online-shop-btn", {
  y: -20,  // Move up by 10 pixels
  duration: 1,  // Duration of the animation
  repeat: -1,  // Infinite repeat
  yoyo: true,  // Reverse the animation
  ease: "power1.inOut"  // Easing function
});




// FADE IN LEFT ------------------------------//
// var target = gsap.utils.toArray(".fadeInLeft");
// gsap.utils.toArray(".fadeInLeft").forEach((target) => {
//   gsap.timeline({
//     scrollTrigger: {
//       trigger: target,
//       start: "top 80%",
//       end: "bottom 80%",
//       toggleActions: "play none none none"
//     }
//   })
//     .from(target, {
//       x: -60, autoAlpha: 0, stagger: 0.5,
//     })
//     .to(target, {
//       x: 0, autoAlpha: 1, ease: "expo.inOut", duration: 1,
//     })
// });

// FADE IN RIGHT ------------------------------//
// var target = gsap.utils.toArray(".fadeInRight");
// gsap.utils.toArray(".fadeInRight").forEach((target) => {
//   gsap.timeline({
//     scrollTrigger: {
//       trigger: target,
//       start: "top 80%",
//       end: "bottom 80%",
//       toggleActions: "play none none none"
//     }
//   })
//     .from(target, {
//       x: 60, autoAlpha: 0, stagger: 0.5,
//     })
//     .to(target, {
//       x: 0, autoAlpha: 1, ease: "expo.inOut", duration: 1,
//     })
// });


// FADE IN UP STAGGER（順番に表示させる） ------------------------------//
// gsap.fromTo('.fadeInUp-stagger',
//   { y: 50, autoAlpha: 0, scale: 0.9 },
//   {
//     y: 0, autoAlpha: 1, scale: 1,
//     stagger: 0.2,
//     ease: "expo.inOut",
//     duration: 1,
//     scrollTrigger: {
//       trigger: '.fadeInUp-stagger',
//       start: "top 80%",
//       end: "bottom 80%",
//       toggleActions: "play none none none"
//     }
//   }
// );

// FADE IN UP STAGGER（順番に表示させる） ------------------------------//
//トリガーはfadeInUp-stagger
gsap.utils.toArray(".fadeInUp-stagger").forEach((target) => {
  const radiusBoxes = target.querySelectorAll('.works-content,.blog-contents,.content');
  //まずはボックス自体を非表示にする
  gsap.set(radiusBoxes, { y: 50, autoAlpha: 0, scale: 0.9 });

  gsap.fromTo(
    radiusBoxes,
    {
      y: 50,
      autoAlpha: 0,
      scale: 0.9,
      filter: "blur(10px)",
    },
    {
      y: 0,
      autoAlpha: 1,
      scale: 1,
      stagger: 0.1,
      filter: "blur(0px)",
      ease: "expo.inOut",
      duration: 1,
      scrollTrigger: {
        trigger: target,
        start: "top 80%",
        end: "bottom 80%",
        toggleActions: "play none none none",
      },
    }
  );
});




